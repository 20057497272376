<template>
  <div>
    <Header></Header>

    <div class="block">
      <el-carousel @change="change1" :height="imgHeight + 'px'" trigger="click">
        <el-carousel-item v-for="item in imgurl" :key="item">
          <img @click="Russia(conster1)" ref="image" :src="item" alt="轮播图" />
        </el-carousel-item>
      </el-carousel>
    </div>

    <div class="switchover_img">
      <img :src="encnimg[num].imgurl" alt="" />
    </div>
    <div class="fs16">
      <p>{{ china[num].china1 }}</p>
      <p>
        {{ china[num].china2 }}
      </p>
      <p>
        {{ china[num].china3 }}
      </p>
    </div>
    <div class="show_img">
      <div>
        <img src="../img/图1.png" alt="" /><img
          src="../img/图2.png"
          alt=""
        /><img src="../img/图3.png" alt="" />
      </div>
      <div>
        <img src="../img/图4.png" alt="" /><img
          src="../img/图5.png"
          alt=""
        /><img src="../img/图6.png" alt="" />
      </div>
    </div>
    <div class="allbox">
      <div class="fs30">{{ titledata[num].title1 }}</div>
      <div class="transverse">
        <img src="../img/标题横杠_image.png" alt="" />
      </div>
      <div class="fs16">{{ titledata[num].title1data }}</div>

      <div class="banner">
        <div class="post_ab">
          <div class="row">
            <div class="first">
              {{ swiperimgdata[num][conster].typeSwiper_first }}
            </div>
            <div class="fs20">
              {{ swiperimgdata[num][conster].typeSwiper_title }}
            </div>
          </div>

          <div class="fs15">
            {{ swiperimgdata[num][conster].typeSwiper_updata }}
          </div>
          <div class="fs15">
            {{ swiperimgdata[num][conster].typeSwiper_downdata }}
          </div>
          <div class="type_router" @click="router(conster)">
            {{ swiperimgdata[num][conster].typeSwiper_more }}
          </div>
        </div>
        <el-carousel
          arrow="always"
          height="4.60rem"
          indicator-position="none"
          @change="change"
        >
          <el-carousel-item v-for="item in swiperimg" :key="item">
            <img @click="router(conster)" :src="item" alt="" />
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
    <div class="fffbox">
      <div class="fs30">{{ titledata[num].title2 }}</div>
      <div class="transverse">
        <img src="../img/标题横杠_image.png" alt="" />
      </div>
      <div class="fs16">{{ titledata[num].title2data }}</div>
      <div class="hoverbox">
        <div
          v-for="(item, index) in hoverimg"
          :key="index"
          class="w25"
          @click="vrRouter(index)"
        >
          <div class="rgba5"></div>
          <div class="pos_r">
            <img :src="item" alt="" />
            <div></div>
            <div class="conceal"><img src="../img/放大_icon.png" alt="" /></div>
          </div>
        </div>
      </div>
    </div>
    <div class="allbox">
      <div class="fs30">{{ titledata[num].title3 }}</div>
      <div class="transverse">
        <img src="../img/标题横杠_image.png" alt="" />
      </div>
      <div class="fs16">
        {{ titledata[num].title3data }}
      </div>

      <div class="mating">
        <div
          class="w24"
          v-for="(item, index) in matingimg[num]"
          :key="index"
          @click="mating()"
        >
          <div class="h270">
            <img class="exit1" :src="matingimg[num][index].url" alt="" />
            <img class="exit2" :src="matingimg[num][index].hoverurl" alt="" />
          </div>
          <div class="text_more">
            <h4>{{ item.title }}</h4>
            <p>{{ item.textCN }}</p>
            <p @click="mating()">{{ item.more }}</p>
          </div>
        </div>
      </div>
    </div>

    <div class="fffboxt">
      <div class="fs30">{{ titledata[num].title4 }}</div>
      <div class="transverse">
        <img src="../img/标题横杠_image.png" alt="" />
      </div>
      <div class="fs16">{{ titledata[num].title4data }}</div>
      <div class="introduce">
        <div class="introduce_left">
          <img src="../img/开发商介绍_image.png" alt="" />
        </div>
        <div class="introduce_right">
          <h1>WING PROPERTY</h1>
          <p :class="num === 1 ? 'hear1' : ''">
            {{ titledata[num].content1 }}
          </p>
          <br />
          <p :class="num === 1 ? 'hear1' : ''">
            {{ titledata[num].content2 }}
          </p>
          <br />
          <p :class="num === 1 ? 'hear1' : ''">
            {{ titledata[num].content3 }}
          </p>
        </div>
      </div>
    </div>
    <Footer></Footer>
    <!-- el-carousel__container -->
  </div>
</template>

<script>
import Header from "../components/Herder.vue";
import Footer from "../components/Footer.vue";
// 引入axios
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState(["num"]),
  },
  components: { Header, Footer },
  name: "First",
  data() {
    return {
      conster: 0,
      conster1: 0,
      isExitHover: true,
      imgurl: [],
      encnimg: [
        {
          imgurl: require("../img/矢量智能对象.png"),
        },
        {
          imgurl: require("../img/headerenimg.png"),
        },
      ],
      titledata: [
        {
          title1: "户型品鉴",
          title1data: "饱览庭院景致，全新居住体验",
          title2: "样板间VR实景",
          title2data: "视觉享受，精致装饰高档豪华",
          title3: "醇熟配套",
          title3data: "苏梅岛城市中心，集生活、商业、娱乐为一体的优质生活圈",
          title4: "开发商介绍",
          title4data: "泰国本土知名顶级老牌开发商，打造海岛传奇",
          content1: `WING PROPERTY成立于1998年，是一家拥有25年开发经验的泰国本土知名开发商，承担过多个泰国中大型项目的开发和施工。业务遍及在万伦，素叻他尼，苏梅岛等地，目前完工项目20余个，凭借良好的声誉和高质量的项目交付，成为泰国顶级老牌开发商。WINGPROPERTY董事长于2015年被任命为苏梅岛副市长。`,
          content2: `WING PROPERTY致力于在维持自然环境的同时，不断提高居民的生活质量，发展更好的生活。本着“为更好的生活而发展”的指导原则，我们的住宅以优越的地理位置、精心设计全面满足客户需求。为投资者和客户提供良好租金回报的租赁管理。`,
          content3: ``,
        },
        {
          title1: "Unit Types",
          title1data: "",
          title2: "Multiple Types",
          title2data: "",
          title3: "Prime Location",
          title3data: "",
          title4: "Developer Introduction",

          title4data: "",
          content1: `Wing Property is a leading full-scale real estate developer in Thailand, and it has built its reputation since 1998 with an impressive portfolio of over 20 completed projects ranging from residential and hospitality developments to the government projects throughout Surat Thani`,
          content2: `With a good reputation and high quality projects delivered, the chairman of Wing Property Ltd.had been appointed as Deputy Mayor of Koh Samui in 2015. To look beyond mere personal success,Wing Property strives to continuously improve the quality of life of our residents with our commitment to develop for better living and sustain the beautiful nature surrounding`,
          content3: `With our guiding principle of “Developing for better living” , our residence encompasses the key ingredients of prime location, meticulously designed,  well-made homes, comprehensive services to fully attend to the needs of our customers, rental management to provide a good rental return for our investor-buyers. We endeavour to innovate and develop our projects that is outstanding, high quality and environmental
                     friendly, to deliver satisfaction to all customers and communities`,
        },
      ],
      china: [
        {
          china1: "羽翼苏梅WING SAMUI是位于泰国苏梅岛的永久产权新建住宅项目。",
          china2:
            "项目位于苏梅岛查汶海滩核心生活区，紧邻环岛唯一主路。羽翼苏梅住宅项目采用顶级大师气候设计理念，",
          china3:
            "利用气压形成“风谷”自然降温，打造生态社区。社区拥有集生活、商业、娱乐为一体的优质配套。",
        },
        {
          china1: "",
          china2: "",
          china3: "",
        },
      ],
      swiperimg: [
        require("../img/A户型品鉴-右_image.png"),
        require("../img/B户型品鉴-右_image.png"),
        require("../img/C户型品鉴-右_image.png"),
        require("../img/D户型品鉴-右_image.png"),
        require("../img/E户型品鉴-右_image.png"),
      ],
      swiperimgdata: [
        [
          {
            typeSwiper_first: "A",
            typeSwiper_title: "户型品鉴",
            typeSwiper_updata: "户型面积：28.97㎡——34.83㎡",
            typeSwiper_downdata: "高阶美学：美感与舒适度兼修的生活空间",
            typeSwiper_router: "",
            typeSwiper_more: "查看户型 >>",
          },
          {
            typeSwiper_first: "B",
            typeSwiper_title: "户型品鉴",
            typeSwiper_updata: "户型面积：50.28㎡——59.54㎡",
            typeSwiper_downdata: "单侧排布：利用楼栋排布产生气压形成穿堂风",
            typeSwiper_router: "",
            typeSwiper_more: "查看户型 >>",
          },
          {
            typeSwiper_first: "C",
            typeSwiper_title: "户型品鉴",
            typeSwiper_updata: "户型面积：38.35㎡——41.25㎡",
            typeSwiper_downdata:
              "极致视野：通透户型 充分采光 阳光山林 尽收眼底",
            typeSwiper_router: "",
            typeSwiper_more: "查看户型 >>",
          },
          {
            typeSwiper_first: "D",
            typeSwiper_title: "户型品鉴",
            typeSwiper_updata: "户型面积：24.22㎡——24.65㎡",
            typeSwiper_downdata:
              "智能住宅：智能客控系统 智慧/舒适/安全/节能/随心所欲",
            typeSwiper_router: "",
            typeSwiper_more: "查看户型 >>",
          },
          {
            typeSwiper_first: "E",
            typeSwiper_title: "户型品鉴",
            typeSwiper_updata: "户型面积：68.75㎡",
            typeSwiper_downdata: "健康住宅：环保绿色家居 安心入住",
            typeSwiper_router: "",
            typeSwiper_more: "查看户型 >>",
          },
        ],
        [
          {
            typeSwiper_first: "A",
            typeSwiper_title: "Type A",
            typeSwiper_updata: "28.97㎡——34.83㎡",
            typeSwiper_downdata: "Superb Comfort Design",
            typeSwiper_router: "",
            typeSwiper_more: "More >>",
          },
          {
            typeSwiper_first: "B",
            typeSwiper_title: "Type B",
            typeSwiper_updata: "50.28㎡——59.54㎡",
            typeSwiper_downdata: "Zen Interior Design",
            typeSwiper_router: "",
            typeSwiper_more: "More >>",
          },
          {
            typeSwiper_first: "C",
            typeSwiper_title: "Type C",
            typeSwiper_updata: "38.35㎡——41.25㎡",
            typeSwiper_downdata: "Natural Flow",
            typeSwiper_router: "",
            typeSwiper_more: "More >>",
          },
          {
            typeSwiper_first: "D",
            typeSwiper_title: "Type D",
            typeSwiper_updata: "24.22㎡——24.65㎡",
            typeSwiper_downdata: "Ultimate Living Solution",
            typeSwiper_router: "",
            typeSwiper_more: "More >>",
          },
          {
            typeSwiper_first: "E",
            typeSwiper_title: "Type E",
            typeSwiper_updata: "68.75㎡",
            typeSwiper_downdata: "Well-made Homes",
            typeSwiper_router: "",
            typeSwiper_more: "More >>",
          },
        ],
      ],
      matingimg: [
        [
          {
            url: require("../img/yiliao_image.png"),
            hoverurl: require("../img/医疗配套_image.png"),
            textCN: "泰国医院提供五星级服务，私立医院基础设施普遍较为完善...",
            title: "医疗配套篇",
            more: "查看更多 >>",
          },
          {
            url: require("../img/jiaoyu_image.png"),
            hoverurl: require("../img/教育配套_image.png"),
            textCN: "学校的教育设施完善，拥有国际标准的教室、室内空调体育场...",
            title: "教育配套篇",
            more: "查看更多 >>",
          },
          {
            url: require("../img/yule_image.png"),
            hoverurl: require("../img/娱乐配套_image.png"),
            textCN: "家门口体验健康精致生活，各国美食餐厅及风情格调酒吧入驻...",
            title: "娱乐配套篇",
            more: "查看更多 >>",
          },
          {
            url: require("../img/shenghuo_image.png"),
            hoverurl: require("../img/生活配套_image.png"),
            textCN: "丰富生活配套，提供便利生活服务711便利店、快餐店...",
            title: "生活配套篇",
            more: "查看更多 >>",
          },
        ],
        [
          {
            url: require("../img/yiliao_image.png"),
            hoverurl: require("../img/医疗配套_image.png"),
            textCN: "Hospitals",
            title: "Hospitals",
            more: "More >>",
          },
          {
            url: require("../img/jiaoyu_image.png"),
            hoverurl: require("../img/教育配套_image.png"),
            textCN: "Education",
            title: "Education",
            more: "More >>",
          },
          {
            url: require("../img/yule_image.png"),
            hoverurl: require("../img/娱乐配套_image.png"),
            textCN: "Entertainment",
            title: "Entertainment",
            more: "More >>",
          },
          {
            url: require("../img/shenghuo_image.png"),
            hoverurl: require("../img/生活配套_image.png"),
            textCN: "Living",
            title: "Living",
            more: "More >>",
          },
        ],
      ],
      hoverimg: [
        require("../img/客厅_image.png"),
        require("../img/卧室_image.png"),
        require("../img/厨房_image.png"),
        require("../img/卫生间_image.png"),
      ],

      imgHeight: 500,
    };
  },
  watch: {
    num(oldValue, newValue) {
      console.log(oldValue);
      console.log(newValue);
      this.info();
    },
  },
  methods: {
    info() {
      if (this.num == 0) {
        this.imgurl = [
          require("../img/newBanner.jpg"),
          require("../img/banner1.jpg"),
          require("../img/banner2.jpg"),
          require("../img/banner3.jpg"),
          require("../img/banner4.jpg"),
          require("../img/banner5.jpg"),
        ];
      } else {
        this.imgurl = [
          require("../img/newBanner.jpg"),
          require("../img/banner1.jpg"),
          require("../img/banner2.jpg"),
          require("../img/banner3.jpg"),
          require("../img/banner4.jpg"),
          require("../img/banner5.jpg"),
        ];
      }
    },
    vrRouter(index) {
      switch (index) {
        case 0:
          {
            window.location.href = "https://vr.om.cn/view/68850";
          }
          break;
        case 1:
          {
            window.location.href = "https://vr.om.cn/view/68850";
          }
          break;
        case 2:
          {
            window.location.href = "https://vr.om.cn/view/96465";
          }
          break;
        case 3:
          {
            window.location.href = "https://vr.om.cn/view/96466";
          }
          break;
        case 4:
          {
            window.location.href = "https://vr.om.cn/view/96500";
          }
          break;
      }
    },
    Russia(index) {
      // if (index == 1 && this.num == 0) {
      //   this.$router.push("/Newparticulars?id=183");
      // }
      // if (index == 1 && this.num == 1) {
      //   this.$router.push("/Newparticulars?id=183");
      // }
      if (index == 0 && this.num == 0) {
        this.$router.push("/Newparticulars?id=182");
      }
    },
    mating() {
      this.$router.push("/mating");
    },
    router(conster) {
      switch (conster) {
        case 0:
          {
            this.$router.push("/typeA");
          }
          break;
        case 1:
          {
            this.$router.push("/typeB");
          }
          break;
        case 2:
          {
            this.$router.push("/typeC");
          }
          break;
        case 3:
          {
            this.$router.push("/typeD");
          }
          break;
        case 4:
          {
            this.$router.push("/typeE");
          }
          break;
      }
    },
    imgLoad() {
      this.$nextTick(function () {
        // 获取窗口宽度*图片的比例，定义页面初始的轮播图高度
        this.imgHeight = (document.body.clientWidth * 1) / 4;
      });
    },
    change(index) {
      this.conster = index;
    },
    change1(index) {
      this.conster1 = index;
    },
  },

  mounted() {
    this.info();
    this.imgLoad();
    this.$nextTick(() => {
      this.imgHeight = this.$refs.image[0].height;
    }),
      // 监听窗口变化，使得轮播图高度自适应图片高度
      window.addEventListener("resize", () => {
        this.imgHeight = this.$refs.image[0].height;
      });
    window.addEventListener("load", () => {
      this.imgHeight = this.$refs.image[0].height;
    });
  },
};
</script>

<style lang="less" scope>
.fs16 p {
  margin: 0.2rem auto;
}
.hear1 {
  line-height: 0.2rem !important;
}
.first {
  font-style: oblique;
}
.row {
  font-size: 0.35rem;
  color: #998570;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 0.55rem;
  margin-bottom: 0.4rem;
}
.fs20 {
  font-size: 0.2rem;
  color: black;
  margin-left: 0.2rem;
}
.text_more {
  line-height: 0.2rem;
}
.fs15 {
  font-size: 0.15rem;
  color: #666666;
  margin-bottom: 0.05rem;
}
.type_router {
  margin-top: 0.7rem;
  color: #998570;
  cursor: pointer;
}
.el-carousel__arrow {
  position: absolute;
  top: 50% !important;
}
.banner .el-carousel__arrow--right {
  left: 10% !important;
  z-index: 999 !important;
  background-color: rgba(0, 0, 0, 0.2) !important;
  background-color: white !important;
  border: 0.01rem solid #998570 !important;
  color: #998570 !important;
  i {
    font-weight: 800 !important;
  }
}
.banner .el-carousel__arrow--left {
  left: 4% !important;
  z-index: 999 !important;
  background-color: rgba(0, 0, 0, 0.2) !important;
  background-color: white !important;
  border: 0.01rem solid #998570 !important;
  color: #998570 !important;
  i {
    font-weight: 800 !important;
  }
}
.banner .el-carousel__arrow--left:hover {
  background-color: rgba(0, 0, 0, 0.1) !important;
}
.banner .el-carousel__arrow--right:hover {
  background-color: rgba(0, 0, 0, 0.1) !important;
}
.el-carousel__container {
  position: relative;
}
.banner .el-carousel__item img {
  width: 100%;
  height: 100%;
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
}

.introduce {
  width: 12.6rem;
  display: flex;
  margin: 0 auto;
}
.introduce_left {
  width: 6.75rem;
  height: 4.4rem;
  img {
    width: 100%;
    height: 100%;
  }
}
.introduce_right {
  padding-left: 0.5rem;
  flex: 1;
  h1 {
    margin: 0 0.1rem 0.2rem 0.1rem;
    font-size: 0.21rem;
  }
  p {
    letter-spacing: 0.01rem;
    line-height: 0.4rem;
    margin-left: 0.1rem;
  }
}
.w24 .exit1 {
  display: inline-block;
}
.w24 .exit2 {
  display: none;
}
.w24:hover .exit1 {
  display: none;
}
.w24:hover .exit2 {
  display: inline-block;
}
.w24 {
  width: 24%;
  margin-right: 1%;
  background-color: white;
}
.w24 img {
  width: 100%;
}
.text_more {
  text-align: center;
  h4 {
    font-weight: 600;
    margin-top: 0.3rem;
  }
  p {
    padding: 0 0.5rem;
    margin-top: 0.3rem;
    color: #666666;
  }
}
.banner {
  width: 12.6rem;
  margin: 0 auto;
  display: -moz-box;
  display: -webkit-box;
  display: banner;
  display: flex;
  justify-content: flex-end;
  -webkit-justify-content: flex-end;
  position: relative;

  .el-carousel {
    width: 7.55rem;
  }
  .post_ab {
    width: 6.64rem;
    height: 3.15rem;
    position: absolute;
    background-color: white;
    top: 0.8rem;
    left: 0;
    z-index: 99;
    padding-left: 0.7rem;
    box-sizing: border-box;
  }
}
.mating {
  height: 4.7rem;
  display: flex;
  flex-direction: column;
  width: 12.6rem;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  cursor: pointer;
}
.pos_r {
  position: relative;
  div {
    height: 1rem;
    width: 1rem;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    img {
      width: 100%;
      height: 100%;
      display: none;
    }
  }
}
.w25:hover .pos_r img {
  display: block;
}
.w25:hover {
  box-shadow: 10px 10px 5px #ccc;
}
.w25 {
  width: 25%;
  position: relative;
  height: 100%;
  box-sizing: border-box;
  img {
    width: 100%;
    height: 100%;
  }

  .rgba5 {
    background-color: #998570;
    opacity: 0.5;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99;
  }
}
.rgba5:hover {
  opacity: 0;
}
.fffbox {
  height: 9.35rem;
  width: 100%;
  background-color: white;
  .hoverbox {
    height: 5.8rem;
    width: 12.6rem;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    cursor: pointer;
  }
}
.fffboxt {
  height: 7.5rem;
  background-color: white;
  width: 100%;
}
.transverse {
  width: 100%;
  height: 0.03rem;
  margin: 0.25rem auto;
  img {
    width: 0.45rem;
    height: 100%;
    margin: 0 auto;
  }
}
.fs30 {
  font-size: 0.3rem;
  text-align: center;
  margin-top: 1rem;
}
.fs16 {
  font-size: 0.16rem;
  text-align: center;
  margin-top: 1rem;
}
.typebox {
  width: 12.6rem;
  height: 4.6rem;
  margin: 0 auto;
  background-color: aquamarine;
}
.allbox {
  width: 100%;
  height: 8.2rem;
  background-color: #f2f2f2;
  overflow: hidden;
}
.show_img {
  width: 12.6rem;
  height: 6.1rem;
  margin: 0 auto;
  margin-bottom: 1rem;
  div {
    display: flex;
    flex-direction: row;
  }
}
.show_img :nth-child(2) {
  margin-top: 0.1rem;
}
.show_img div :nth-child(2) {
  margin: 0 0.1rem;
}
.fs16 {
  font-size: 0.16rem;
  text-align: center;
  color: #666666;
  margin: 0.6rem auto;
}
.switchover_img {
  width: 12.6rem;
  height: 0.78rem;

  margin: 0 auto;
  margin-top: 1.1rem;

  img {
    width: 5.33rem;
    margin: 0 auto;
    display: block;
  }
}
.carousel {
  width: 100%;
  height: 7.5rem;
  overflow: hidden;
  max-width: 100%;
  min-width: 99%;
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
}
.block {
  width: 100%;
  margin: 0 auto;
  max-width: 100%;
  min-width: 99%;
}
.el-carousel__container {
  width: 100%;
}
.el-carousel {
  width: 100%;
}
.el-carousel__item {
  width: 100%;
  cursor: pointer !important;
}
.el-carousel__item img {
  width: 100%;
}
</style>