<template>
  <div class="footerbox">
    <div class="banner">
      <div class="upbox">
        <div class="imgbox"><img src="../img/logo拷贝.png" alt="" /></div>
      </div>
      <div class="catalogue">
        <h3>{{ footerdata[num].title }}</h3>
        <div class="spanbox">
          <span
            ><router-link :to="{ path: '/' }">{{
              footerdata[num].span1
            }}</router-link></span
          >
          <span
            ><router-link :to="{ path: '/manager' }">{{
              footerdata[num].span2
            }}</router-link></span
          >
        </div>
        <div class="spanbox">
          <span
            ><router-link :to="{ path: '/introduce' }">{{
              footerdata[num].span3
            }}</router-link></span
          >
          <span
            ><router-link :to="{ path: '/developers' }">{{
              footerdata[num].span4
            }}</router-link></span
          >
        </div>
        <div class="spanbox">
          <span
            ><router-link :to="{ path: '/typeA' }">{{
              footerdata[num].span5
            }}</router-link></span
          >
          <span
            ><router-link :to="{ path: '/news' }">{{
              footerdata[num].span6
            }}</router-link></span
          >
        </div>
        <div class="spanbox">
          <span
            ><router-link :to="{ path: '/mating' }">{{
              footerdata[num].span7
            }}</router-link></span
          >
          <span
            ><router-link :to="{ path: '/contact' }">{{
              footerdata[num].span8
            }}</router-link></span
          >
        </div>
      </div>
      <div class="catalogue">
        <h3>{{ footerdata[num].span8 }}</h3>
        <div class="spanbox">
          <span>{{ footerdata[num].span9 }}</span>
        </div>
        <div class="spanbox">
          <span>{{ footerdata[num].span10 }}</span>
        </div>
        <div class="spanbox">
          <span>{{ footerdata[num].span11 }}</span>
        </div>
      </div>
      <div class="catalogue">
        <h3>{{ footerdata[num].h3 }}</h3>
        <div class="spanbox">
          <span @click="vxblackFun"
            ><img src="../img/wechat-icon.png" alt="" />
            <div class="vx" v-show="vxblack">
              <img src="../img/图层18.png" alt="" /></div
          ></span>
          <span @click="lingFun"
            ><img src="../img/LING_icon.png" alt="" />
            <div class="vx" v-show="lingblack">
              <img
                src="../img/68faf8bab8c4189ed370ba28c10eff5.jpg"
                alt=""
              /></div
          ></span>
        </div>
      </div>
    </div>
    <div class="downbox">
      <p>{{ footerdata[num].p1 }}</p>
      <div>
        <p v-if="num == 0">
          <img src="../img/national emblem.png" alt="" /><span>{{
            footerdata[num].p2
          }}</span>
        </p>
      </div>
      <!-- <div class="link">
        <div style="color: #ffffff" @click="link()">
          Конвертировать рубли в доллары
        </div>
        <div style="color: #ffffff" @click="link()">
          Купить квартиру в рублях
        </div>
        <div style="color: #ffffff" @click="link()">
          Используйте рублидля покупки активов в долларах
        </div>
        <div style="color: #ffffff" @click="map()">e-Sitemap</div>
      </div> -->
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState(["num"]),
  },
  methods: {
    vxblackFun() {
      this.vxblack = !this.vxblack;
    },
    lingFun() {
      this.lingblack = !this.lingblack;
    },
    link() {
      this.$router.push("/Russia");
    },
    map() {
      window.location = "https://www.wingsamui.com/e-sitemap.xml";
    },
  },
  data() {
    return {
      vxblack: false,
      lingblack: false,
      footerdata: {
        0: {
          title: "网站目录",
          span1: "首页",
          span2: "物业管理",
          span3: "项目详情",
          span4: "开发商介绍",
          span5: "户型品鉴",
          span6: "新闻中心",
          span7: "周边配套",
          span8: "联络我们",
          span9: "微信号:wingsamuithailand",
          span10: "Email:service@wingsamui.com",
          span11:'电话: 01084629845',
          h3: "",
          p1: "Copyright 2019 All Rights Reserved WING SAMUI 京ICP备19058724号-3",
          p2: "京公网安备 11010802031686号",
        },
        1: {
          title: "Website Directory",
          span1: "Home",
          span2: "Property",
          span3: "Project Details",
          span4: "Developer Introduction",
          span5: "Unit Types",
          span6: "News",
          span7: "Prime Location",
          span8: "Contact Us",
          span9: "Wechat: wingsamuithailand",
          span10: "Email:service@wingsamui.com",
          span11:"phone:01084629845",
          h3: "",
          p1: "",
          p2: "",
        },
      },
    };
  },
};
</script>

<style lang="less" scoped>
.link {
  display: flex;
  height: 50px;
  div {
    // width: 33%;
    margin-left: 150px;
    // font-size: 20px;
    text-align: center;
    // margin: -50px auto;
    cursor: pointer;
  }
}
.vx {
  position: absolute;
  top: 50px;
  left: -40px;
  img {
    width: 100%;
    height: 100%;
  }
}
.spanbox {
  display: flex;
}
.catalogue {
  height: 100%;
  width: 300px;
  display: flex;
  flex-direction: column;
  margin-left: 0.5rem;
  justify-content: center;
  span {
    display: block;
    width: 1.9rem;
    margin-left: 0.2rem;
    margin-top: 0.2rem;
    position: relative;
    a {
      color: white;
    }
  }
  h3 {
    color: white;
    margin-left: 0.2rem;
  }
  div {
    width: 100%;
    color: white;
  }
}
.footerbox {
  background-color: black;
  display: flex;
  flex-direction: column;
}
.upbox {
  width: 2rem;
  height: 100%;
  display: flex;
  .imgbox {
    margin: auto;
    height: 0.9rem;
    width: 100%;
  }
}
.banner {
  width: 12.6rem;
  margin: 0 auto;
  height: 3.25rem;
  display: flex;
  align-items: center;
}
.downbox {
  color: #666666;
}
.downbox p {
  height: 0.2rem;
  text-align: center;
}
.downbox :nth-child(2) {
  display: flex;
}
.downbox :nth-child(2) p {
  display: flex;
  align-items: center;
  justify-items: center;
  margin: auto;
  img {
    width: 0.2rem;
    height: 0.2rem;
  }
  span {
    display: block;
  }
}
</style>