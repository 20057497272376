import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../views/Index.vue'
// import store from '../store/index'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Index',
    component: Index
  },
  {
    path: '/particulars',
    name: 'particulars',
    component: () => import('../views/particulars.vue'),
    children: [
      {
        path: '/',
        name: 'about',
        component: () => import('../views/particulars/about.vue')
      },
      {
        path: '/introduce',
        name: 'introduce',
        component: () => import('../views/particulars/introduce.vue')
      },
      {
        path: '/team',
        name: 'team',
        component: () => import('../views/particulars/team.vue')
      },
      {
        path: '/lightspot',
        name: 'lightspot',
        component: () => import('../views/particulars/lightspot.vue')
      },
      {
        path: '/china',
        name: 'china',
        component: () => import('../views/particulars/China.vue')
      },
    ]
  },
  {
    path: '/plan',
    name: 'Plan',
    redirect: '/typeA',
    component: () => import('../views/plan.vue'),
    children: [
      {
        path: '/typeA',
        name: 'typeA',
        component: () => import('../views/Plan/planA.vue')
      },
      {
        path: '/typeB',
        name: 'typeB',
        component: () => import('../views/Plan/planB.vue')
      },
      {
        path: '/typeC',
        name: 'typeC',
        component: () => import('../views/Plan/planC.vue')
      },
      {
        path: '/typeD',
        name: 'typeD',
        component: () => import('../views/Plan/planD.vue')
      },
      {
        path: '/typeE',
        name: 'typeE',
        component: () => import('../views/Plan/planE.vue')
      },
    ]
  },
  {
    path: '/mating',
    name: 'mating',
    component: () => import('../views/mating.vue')
  },
  {
    path: '/manager',
    name: 'manager',
    component: () => import('../views/manager.vue')
  },
  {
    path: '/developers',
    name: 'developers',
    component: () => import('../views/developers.vue')
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import('../views/contact.vue')
  },
  {
    path: '/Russia',
    name: 'Russia',
    component: () => import('../views/Russia.vue')
  },
  {
    path: '/news',
    name: 'news',
    component: () => import('../views/news.vue')
  },
  {
    path: '/Newparticulars',
    name: 'newparticulars',
    component: () => import('../views/newparticulars.vue'),
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    // 解决路由跳转后 会滚动到底部
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
})
// router.beforeEach((to, from, next) => {
//   if (to.name == 'Index') {
//     // let a = window.location.hostname  获取线上地址
//     let a = 'www.wingsamui.com' // 国外
//     let b = a.split('.')[2]
//     if (b == 'cn') {
//       store.state.num = 0
//       // console.log(store.state,'222')
//     } else {
//       store.state.num = 1
//       // console.log(store.state,'222')
//     }
//     next()
//   }
//   next()
// })

/* 解决重复跳转BUG */
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject)
    return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch((err) => err)
}

export default router
