<template>
  <div class="w100">
    <div class="area">
      <div class="crea_imgbox" @click="toIndex">
        <img src="../img/logo@3x.png" alt="" />
      </div>
      <div class="zhanwei" :class="num === 1 ? 'none' : 'zhanwei'"></div>
      <div class="area_nav">
        <span
          v-for="(item, index) in navarr"
          :key="index"
          @click="router(index)"
          >{{ item[num] }}</span
        >
        <span @click="changenum()" class="span">
          <li class="list-inline-item menuDiv">
            <div class="nav_flag" v-show="this.num == 0"><img src="../img/China.png" alt=""><a href="#" class="menuA">中文</a></div>
            <div class="nav_flag" v-show="this.num == 1"><img src="../img/English.png" alt=""><a href="#">English</a></div>
            <ul class="menuUL">
              <li class="menuLI">
                <div class="nav_flag" v-show="this.num == 0"><img src="../img/English.png" alt=""><a href="#">English</a></div>
                <div class="nav_flag" v-show="this.num == 1"><img src="../img/China.png" alt=""><a href="#" class="menuA">中文</a></div>
              </li>
            </ul>
          </li>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
export default {
  computed: {
    ...mapState(["num"]),
  },

  methods: {
    ...mapMutations(["changenum"]),
    toIndex() {
      this.$router.push("/");
    },
    router(index) {
      switch (index) {
        case 0:
          {
            this.$router.push("/");
          }
          break;
        case 1:
          {
            this.$router.push("/particulars");
          }
          break;
        case 2:
          {
            this.$router.push("/Plan");
          }
          break;
        case 3:
          {
            this.$router.push("/mating");
          }
          break;
        case 4:
          {
            this.$router.push("/manager");
          }
          break;
        case 5:
          {
            this.$router.push("/developers");
          }
          break;
        case 6:
          {
            this.$router.push("/news");
          }
          break;
        case 7:
          {
            this.$router.push("/contact");
          }
          break;
      }
    },
  },

  data() {
    return {
      navarr: [
        { 0: "首页", 1: "Home" },
        { 0: "项目详情", 1: "Wing Samui" },
        { 0: "户型品鉴", 1: "Unit Types" },
        { 0: "醇熟配套", 1: "Prime Location" },
        { 0: "物业管理", 1: "Property" },
        { 0: "开发商介绍", 1: "About Developer" },
        { 0: "新闻中心", 1: "News" },
        { 0: "联络我们", 1: "Contact Us" },
      ],
    };
  },
};
</script>

<style lang="less" scoped>
.list-inline-item{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.nav_flag {
  display: flex;
  align-items: center;
  img{
    height: 0.14rem;
    width: 0.21rem;
    margin-right: 0.10rem;
  }
}
.menuDiv {
  overflow: hidden;
  width: 100px;
  line-height: 40px;
  color: black;
  text-align: center;
  display: block;
}
/*隐藏二级菜单*/
.menuUL {
  position: absolute;
  display: none;
}
/*鼠标移到盒子，显示二级菜单*/
.menuDiv:hover ul {
  display: block;
}

/*二级菜单样式*/
.menuLI {
  width: 100px;
  line-height: 36px;
  text-align: center;
  border-top: none;
  display: block;
}

.none {
  display: none;
}
.fs15 {
  font-size: 0.01rem !important;
}

.span {
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
}
.w100 {
  width: 100%;
  height: 1.2rem;
  background-color: white;
}
.area {
  height: 1.2rem;
  width: 12rem;
  margin: 0 auto;
  display: flex;
  align-items: center;
}
.crea_imgbox {
  height: 100%;
  width: 1.5rem;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.crea_imgbox img {
  width: 100%;
  line-height: 1.2rem;
}
.zhanwei {
  width: 1.54rem;
  height: 1.2rem;
}
.area_nav {
  flex: 1;
  display: flex;
  span {
    flex: 1;
    text-align: center;
    font-size: 0.14rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.area_nav :nth-child(6) {
  text-indent: -0.05rem;
}
.area_nav span:hover {
  color: #927857;
  cursor: pointer;
}
</style>